<template>
  <div>
    <b-modal ref="dialog" @ok="deleteFarmersOperators">削除してもよろしいですか</b-modal>
  </div>
</template>

<script>
import { DELETE_FARMER_OPERATOR } from "../../components/farmer-operator-mutation";

export default {
  props: {
    propFarmersOperatorsId: {
      default: null
    }
  },
  watch: {
    propFarmersOperatorsId: function() {
      this.farmersOperatorsId = this.propFarmersOperatorsId;
    }
  },
  data() {
    return {
      farmersOperatorsId: this.propFarmersOperatorsId
    };
  },
  methods: {
    showDialog: function() {
      this.$refs["dialog"].show();
    },
    // --------------------------------
    // 削除
    // --------------------------------
    deleteFarmersOperators: function() {
      this.$apollo
        .mutate({
          mutation: DELETE_FARMER_OPERATOR,
          variables: {
            id: this.farmersOperatorsId
          }
        })
        .then(data => {
          const farmerId =
            data.data.deleteFarmerOperator.farmerOperator.farmer.id;
          this.$emit("getDeletedFarmerId", farmerId);
        })
        .catch(error => {
          console.error(error);
          alert(error);
        });
    }
  }
};
</script>
