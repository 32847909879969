<template>
  <div>
    <b-modal ref="dialog" @ok="deleteUser">削除してもよろしいですか</b-modal>
  </div>
</template>

<script>
import { DELETE_OPERATOR } from "../../components/operator-mutation";

export default {
  props: ["propUserId"],
  watch: {
    propUserId: function() {
      this.init();
    }
  },
  data() {
    return {
      userId: this.propUserId
    };
  },
  methods: {
    init: function() {
      this.userId = this.propUserId;
    },
    showDialog: function() {
      this.$refs["dialog"].show();
    },
    // --------------------------------
    // 削除
    // --------------------------------
    deleteUser: function() {
      this.$apollo
        .mutate({
          mutation: DELETE_OPERATOR,
          variables: {
            id: this.userId
          }
        })
        .then(data => {
          this.$emit("refetch");
        })
        .catch(error => {
          console.error(error);
          alert(error);
        });
    }
  }
};
</script>
