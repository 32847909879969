<template>
  <div>
    <b-form-group>
      <b-form-select v-model="farmer" v-on:change="regist" required :state="validation">
        <option :value="null" disabled>-- 農家を選択してください --</option>
        <option
          v-for="item in farmers.edges"
          :key="item.node.id"
          :value="item.node"
        >{{ item.node.name }}</option>
      </b-form-select>
      <b-form-invalid-feedback :state="validation">
        {{
        validMsg
        }}
      </b-form-invalid-feedback>
    </b-form-group>
  </div>
</template>
<script>
import { FARMER_QUERY } from "../../../components/farmer-query";
import { FARMER_ID } from "../../../components/auth-settings";
export default {
  props: {
    propFarmer: {
      default: null
    },
    propValidMsg: {
      default: ""
    },
    propValidation: {
      default: null
    }
  },
  data() {
    return {
      farmer: this.propFarmer,
      farmers: [],
      validMsg: "",
      validation: null
    };
  },
  watch: {
    propFarmer: function() {
      this.farmer = this.propFarmer;
    },
    propValidMsg: function() {
      this.validMsg = this.propValidMsg;
    },
    propValidation: function() {
      this.validation = this.propValidation;
    }
  },
  apollo: {
    farmers: {
      query: FARMER_QUERY,
      result({ data, loading }) {},
      error(error) {
        console.error("farmer", error);
      }
    }
  },
  methods: {
    regist: function() {
      this.$emit("changeFarmer", this.farmer);
    }
  }
};
</script>
