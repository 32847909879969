import gql from "graphql-tag";

export const CURRENT_OPERATOR_QUERY = gql`
  query($id: ID!) {
    operator(id: $id) {
      id
      username
      firstName
      lastName
      email
      password
      authgroupsoperatorsSet {
        edges {
          node {
            id
            authGroup {
              id
              name
            }
            farmer {
              id
              name
            }
          }
        }
      }
      farmersoperatorsSet {
        edges {
          node {
            id
            farmer {
              id
              name
            }
          }
        }
      }
    }
  }
`;

export const OPERATOR_QUERY = gql`
  query {
    operators {
      edges {
        node {
          id
          username
          firstName
          lastName
          email
          authgroupsoperatorsSet {
            edges {
              node {
                id
                authGroup {
                  id
                  name
                }
                farmer {
                  id
                  name
                }
              }
            }
          }
          farmersoperatorsSet {
            edges {
              node {
                id
                farmer {
                  id
                  name
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const OPERATORS_FARMER_QUERY = gql`
  query($id: ID!) {
    operator(id: $id) {
      id
      username
      email
      password
      farmersoperatorsSet {
        edges {
          node {
            id
            farmer {
              id
              name
            }
          }
        }
      }
    }
  }
`;

export const OPERATORS_AUTH_GROUP_QUERY = gql`
  query($id: ID!) {
    operator(id: $id) {
      id
      username
      email
      password
      authgroupsoperatorsSet {
        edges {
          node {
            id
            authGroup {
              id
              name
            }
            farmer {
              id
              name
            }
          }
        }
      }
    }
  }
`;
