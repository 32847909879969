<template>
  <div>
    <b-link @click="showModal">パスワード変更</b-link>
    <b-modal ref="dialog" @hidden="close" hide-footer title="パスワード変更">
      <b-form-group
        id="password-input"
        label="新しいパスワードを入力"
        label-for="password"
        description="半角英数字8文字以上ハイフン・アンダーバーが使えます"
      >
        <b-form-input
          id="password"
          type="password"
          v-model="password"
          placeholder="新しいパスワードを入力"
          required
          pattern="([a-z]|[A-Z]|[0-9]|-|_){8,}$"
          :state="validStatus"
        ></b-form-input>
        <b-form-invalid-feedback :state="validStatus">
          {{
          validMsg
          }}
        </b-form-invalid-feedback>
      </b-form-group>
      <b-form-group id="password-input" label="パスワード確認用" label-for="password">
        <b-form-input
          id="rePassword"
          type="password"
          v-model="rePassword"
          placeholder="パスワード確認用"
          required
          pattern="([a-z]|[A-Z]|[0-9]|-|_){8,}$"
          :state="validStatus"
        ></b-form-input>
        <b-form-invalid-feedback :state="validStatus">
          {{
          validMsg
          }}
        </b-form-invalid-feedback>
      </b-form-group>
      <b-row>
        <b-col class="text-right">
          <b-button type="submit" variant="outline-primary" v-on:click="updatePassword">変更</b-button>
        </b-col>
      </b-row>
    </b-modal>
  </div>
</template>

<script>
import { UPDATE_OPERATOR_PASSWORD } from "../../components/operator-mutation";

export default {
  props: {
    propOperatorId: {
      default: null
    }
  },
  watch: {
    propOperator: function() {
      this.operatorId = this.propOperatorId;
    }
  },
  data() {
    return {
      operatorId: this.propOperatorId,
      password: null,
      rePassword: null,
      validMsg: "",
      validStatus: null
    };
  },
  methods: {
    // --------------------------------
    // modalを開く
    // --------------------------------
    showModal: function() {
      this.$refs["dialog"].show();
    },
    // --------------------------------
    // modalを閉じる
    // --------------------------------
    close: function() {
      this.$refs["dialog"].hide();
    },
    initValid: function() {
      this.validMsg = "";
      this.validStatus = null;
    },
    checkPassword: function() {
      if (this.password != this.rePassword) {
        this.validMsg = "パスワードが異なっています";
        this.validStatus = false;
      } else {
        this.validMsg = "OK";
        this.validStatus = true;
      }
    },
    emptyValid: function() {
      if (!this.password || !this.rePassword) {
        this.validMsg = "パスワードを入力してください";
        this.validStatus = false;
      }
    },
    updatePassword: function() {
      this.initValid();
      this.emptyValid();
      this.checkPassword();
      if (this.validStatus) {
        this.$apollo
          .mutate({
            mutation: UPDATE_OPERATOR_PASSWORD,
            variables: {
              id: this.operatorId,
              password: this.password
            }
          })
          .then(result => {
            const valid_errors = result.data.updateOperatorPassword.errors;
            if (valid_errors.length != 0) {
              valid_errors.forEach(valid_error => {
                this.validMsg = valid_error.messages[0];
                this.validStatus = false;
              });
            } else {
              this.close();
            }
          })
          .catch(error => {
            console.error(error);
            alert(error);
          });
      }
    }
  }
};
</script>
