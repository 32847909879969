<template>
  <div>
    <b-modal ref="updateDialog" @hidden="close" hide-footer title="編集">
      <b-row>
        <b-col>
          <label>農家</label>
          <operators-farmer-form
            :propOperator="operator"
            :propFarmersOperators="farmersOperators"
            :propValidMsg="validMsg"
            :propValidation="validStatus"
            @changeFarmer="setFarmer"
          />
        </b-col>
        <b-col>
          <label>権限</label>
          <auth-group-form
            :propAuthGroup="authGroup"
            :propValidMsg="validMsg"
            :propValidation="validStatus"
            @getAuthGroup="setAuthGroup"
          ></auth-group-form>
        </b-col>
      </b-row>
      <b-row>
        <b-col class="text-right">
          <b-button variant="outline-primary" v-on:click="onClickFunc">追加</b-button>
        </b-col>
      </b-row>
    </b-modal>
  </div>
</template>
<script>
import AuthGroupForm from "./form/AuthGroupForm";
import OperatorsFarmerForm from "./form/OperatorsFarmerForm";
import { CREATE_AUTH_GROUP_OPERATOR } from "../../components/auth-groups-operators-mutation";
import { CREATE_FARMER_OPERATOR } from "../../components/farmer-operator-mutation";

export default {
  components: {
    AuthGroupForm,
    OperatorsFarmerForm
  },
  props: {
    propOperator: {
      default: null
    }
  },
  watch: {
    propOperator: function() {
      this.operator = this.propOperator;
      this.farmersOperators = this.operator.farmersoperatorsSet.edges;
    }
  },
  data() {
    return {
      operator: null,
      farmer: null,
      authGroup: null,
      farmersOperators: null,
      validMsg: "",
      validStatus: null
    };
  },
  methods: {
    init: function() {
      this.farmer = null;
      this.authGroup = null;
      this.validMsg = "";
      this.validStatus = null;
    },
    // --------------------------------
    // modalを閉じる
    // --------------------------------
    close: function() {
      this.init();
      this.$refs["updateDialog"].hide();
    },
    // --------------------------------
    // modalを開く
    // --------------------------------
    showDialog: function() {
      this.$refs["updateDialog"].show();
    },
    setFarmer: function(farmer) {
      this.farmer = farmer;
    },
    setAuthGroup: function(authGroup) {
      this.authGroup = authGroup;
    },
    checkDuplication: function() {
      let validation = true;
      for (let authGroupSets of this.operator.authgroupsoperatorsSet.edges) {
        if (
          authGroupSets.node.farmer.id == this.farmer.id &&
          authGroupSets.node.authGroup.id == this.authGroup.id
        ) {
          validation = false;
        }
      }
      return validation;
    },
    checkUnregisteredFarmer: function() {
      let validation = false;
      for (let farmerSets of this.farmersOperators) {
        if (farmerSets.node.farmer.id == this.farmer.id) {
          validation = true;
        }
      }
      return validation;
    },
    onClickFunc: function() {
      const duplValid = this.checkDuplication();
      const unregistValid = this.checkUnregisteredFarmer();

      if (duplValid && unregistValid) {
        this.addUserAuthGroup();
        this.$emit("refetch");
        this.close();
      } else {
        this.validStatus = false;
        if (!duplValid) {
          this.validMsg = "すでに登録されています";
        }
        if (!unregistValid) {
          this.validMsg = "農家を先に登録してください";
        }
      }
    },
    addUserAuthGroup: function() {
      this.$apollo.mutate({
        mutation: CREATE_AUTH_GROUP_OPERATOR,
        variables: {
          input: {
            authGroupId: this.authGroup.id,
            operatorId: this.operator.id,
            farmerId: this.farmer.id
          }
        }
      });
    }
  }
};
</script>
