import gql from "graphql-tag";

export const CREATE_OPERATOR = gql`
  mutation createOperator(
    $username: String!
    $firstName: String!
    $lastName: String!
    $password: String!
    $email: String!
  ) {
    createOperator(
      username: $username
      firstName: $firstName
      lastName: $lastName
      password: $password
      email: $email
    ) {
      user {
        id
        username
        firstName
        lastName
        password
        email
      }
      errors {
        field
        messages
      }
    }
  }
`;

export const UPDATE_OPERATOR = gql`
  mutation updateOperator($id: ID!, $input: OperatorInput!) {
    updateOperator(id: $id, input: $input) {
      user {
        id
        username
        firstName
        lastName
        password
        email
      }
      errors {
        field
        messages
      }
    }
  }
`;

export const UPDATE_OPERATOR_PASSWORD = gql`
  mutation updateOperatorPassword($id: ID!, $password: String!) {
    updateOperatorPassword(id: $id, password: $password) {
      user {
        id
        username
        firstName
        lastName
        password
        email
      }
      errors {
        field
        messages
      }
    }
  }
`;

export const DELETE_OPERATOR = gql`
  mutation deleteOperator($id: ID!) {
    deleteOperator(id: $id) {
      user {
        id
        username
        firstName
        lastName
        password
        email
      }
    }
  }
`;
