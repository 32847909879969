<template>
  <div>
    <b-modal ref="dialog" ok-only>ユーザー情報更新しました</b-modal>
    <h4>ユーザー情報</h4>
    <b-form>
      <b-form-group id="username-input" label="Your Name:" label-for="lastName">
        <b-row>
          <b-col>
            <b-form-input id="lastName" v-model="form.lastName"></b-form-input>
          </b-col>
          <b-col>
            <b-form-input id="firstName" v-model="form.firstName"></b-form-input>
          </b-col>
        </b-row>
      </b-form-group>
      <b-form-group id="email-input" label="Email address:" label-for="E-mail" description="半角英数字">
        <b-form-input
          id="email"
          type="email"
          v-model="form.email"
          placeholder="Enter email"
          pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$"
          :state="validStatus"
        ></b-form-input>
        <b-form-invalid-feedback :state="validStatus">
          {{
          validMsg
          }}
        </b-form-invalid-feedback>
      </b-form-group>
      <user-password-update-form :propOperatorId="operator.id"></user-password-update-form>
      <b-row class="mb-3">
        <b-col class="text-right">
          <b-button type="button" variant="primary" v-on:click="onSubmit">ユーザー情報更新</b-button>
        </b-col>
      </b-row>
    </b-form>
  </div>
</template>

<script>
import UserPasswordUpdateForm from "./UserPasswordUpdateForm";
import { UPDATE_OPERATOR } from "../../components/operator-mutation";

const DUPL_EMAIL_ERROR = /Duplicate entry/;
const EMAIL_FORMAT_PATTERN = /[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$/;

export default {
  components: {
    UserPasswordUpdateForm
  },
  props: {
    propOperator: {
      default: null
    }
  },
  watch: {
    propOperator: function() {
      this.operator = this.propOperator;
    }
  },
  data() {
    return {
      operator: this.propOperator,
      form: {
        username: "",
        firstName: "",
        lastName: "",
        email: ""
      },
      validMsg: "",
      validStatus: null
    };
  },
  created() {
    this.form.username = this.operator.username;
    this.form.firstName = this.operator.firstName;
    this.form.lastName = this.operator.lastName;
    this.form.email = this.operator.email;
  },
  methods: {
    onSubmit: function() {
      this.validMsg = "";
      this.validStatus = null;

      this.emailFormatValid();

      this.form.username = this.form.lastName + " " + this.form.firstName;

      if (this.validStatus == null) {
        this.updateOperator();
      }
    },
    emailFormatValid() {
      if (!this.form.email.match(EMAIL_FORMAT_PATTERN)) {
        this.validMsg = "メールアドレスのフォーマットが不正です";
        this.validStatus = false;
      }
    },
    updateOperator: function() {
      this.$apollo
        .mutate({
          mutation: UPDATE_OPERATOR,
          variables: {
            id: this.operator.id,
            input: {
              username: this.form.username,
              firstName: this.form.firstName,
              lastName: this.form.lastName,
              email: this.form.email
            }
          }
        })
        .then(result => {
          const valid_errors = result.data.updateOperator.errors;
          if (valid_errors.length != 0) {
            valid_errors.forEach(valid_error => {
              this.validMsg = valid_error.messages[0];
              this.validStatus = false;
            });
          } else {
            this.operator = result.data.updateOperator.user;
            this.$refs["dialog"].show();
          }
        })
        .catch(error => {
          if (error.message.match(DUPL_EMAIL_ERROR)) {
            this.validMsg = "既に登録されたメールアドレスです";
            this.validStatus = false;
          }
        });
    }
  }
};
</script>
