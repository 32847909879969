<template>
  <div>
    <b-modal ref="dialog" @ok="deleteAuthGroupOperator">削除してもよろしいですか</b-modal>
  </div>
</template>

<script>
import { DELETE_AUTH_GROUP_OPERATOR } from "../../components/auth-groups-operators-mutation";

export default {
  props: {
    propAuthGroupOperatorId: {
      default: null
    }
  },
  watch: {
    propAuthGroupOperatorId: function() {
      this.authGroupOperatorId = this.propAuthGroupOperatorId;
    }
  },
  data() {
    return {
      authGroupOperatorId: this.propAuthGroupOperatorId
    };
  },
  methods: {
    showDialog: function() {
      this.$refs["dialog"].show();
    },
    // --------------------------------
    // 削除
    // --------------------------------
    deleteAuthGroupOperator: function() {
      this.$apollo
        .mutate({
          mutation: DELETE_AUTH_GROUP_OPERATOR,
          variables: {
            id: this.authGroupOperatorId
          }
        })
        .then(data => {
          this.$emit("refetch");
        })
        .catch(error => {
          console.error(error);
          alert(error);
        });
    }
  }
};
</script>
