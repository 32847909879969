<template>
  <div>
    <b-card>
      <div v-show="isSignUp">
        <create-user @createdUser="createdUser" />
      </div>
      <div v-show="isFarmerSet">
        <farmer-operator-set-form :propOperator="operator" @getFarmer="setFarmer" />
      </div>
      <div v-show="isInfoSet">
        <auth-groups-operators-set-form
          ref="authGroupsOperatorsSetForm"
          :propFarmersOperators="farmersOperators"
          :propOperator="operator"
          @getInfo="setInfo"
        />
      </div>
      <div v-show="isNextUser">
        <p>ユーザー登録完了</p>
        <b-button type="button" variant="primary" v-on:click="init">他のユーザーを登録</b-button>
      </div>
    </b-card>
    <b-card>
      <b-row>
        <b-col>
          <h3>ユーザー</h3>
        </b-col>
      </b-row>
      <user-list ref="userList"></user-list>
    </b-card>
  </div>
</template>

<script>
import CreateUser from "../components/CreateUser";
import FarmerOperatorSetForm from "../components/FarmerOperatorSetForm";
import AuthGroupsOperatorsSetForm from "../components/AuthGroupsOperatorsSetForm";
import FarmerForm from "../components/form/FarmerForm";
import UserList from "../components/UserList";

const DEFAULT_INDEX = 0;

export default {
  components: {
    CreateUser,
    FarmerOperatorSetForm,
    AuthGroupsOperatorsSetForm,
    FarmerForm,
    UserList
  },
  data() {
    return {
      operator: null,
      farmersOperators: [],
      isSignUp: true,
      isFarmerSet: false,
      isInfoSet: false,
      isNextUser: false
    };
  },
  methods: {
    init: function() {
      this.operator = null;
      this.farmersOperators = null;
      this.isSignUp = true;
      this.isFarmerSet = false;
      this.isInfoSet = false;
      this.isNextUser = false;
      this.refetch();
    },
    createdUser: function(operator) {
      this.operator = operator;
      this.isSignUp = false;
      this.isFarmerSet = true;
    },
    setFarmer: function(farmersOperators) {
      this.isFarmerSet = false;
      this.isInfoSet = true;
      this.farmersOperators = farmersOperators;
    },
    setInfo: function() {
      this.isInfoSet = false;
      this.isNextUser = true;
      this.refetch();
    },
    refetch: function() {
      this.$refs.userList.refetch();
    }
  }
};
</script>
