<template>
  <div>
    <auth-groups-operators-create-modal-form
      :propOperator="operator"
      :propAuthGroupsOperators="authGroupsOperators"
      ref="authGroupsOperatorsCreateModalForm"
      @refetch="refetch"
    />
    <auth-groups-operators-delete-modal-form
      :propAuthGroupOperatorId="authGroupsOperatorsId"
      ref="authGroupsOperatorsDeleteModalForm"
      @refetch="refetch"
    />
    <farmers-operators-create-modal-form
      :propOperator="operator"
      ref="farmersOperatorsCreateModalForm"
      @refetch="refetch"
    />
    <farmers-operators-delete-modal-form
      :propFarmersOperatorsId="farmersOperatorsId"
      ref="farmersOperatorsDeleteModalForm"
      @getDeletedFarmerId="setDeletedFarmerId"
    />
    <b-modal ref="updateDialog" @hidden="close" hide-footer title="編集">
      <div v-if="operator != null">
        <user-info-update-form :propOperator="operator"></user-info-update-form>
      </div>
      <h4>所属・権限</h4>
      <b-table
        id="farmersOperator"
        responsive="sm"
        :fields="farmerFields"
        :items="this.farmersOperators"
      >
        <template v-if="farmersOperators.length != 0" v-slot:cell(farmer)="data">
          <td>{{data.item.node.farmer.name}}</td>
        </template>
        <template v-slot:cell(delete)="data">
          <b-button
            type="submit"
            size="sm"
            variant="danger"
            class="icon-trash"
            v-bind="farmersOperator"
            v-on:click="showDeleteFarmerDialog(data.item.node.id)"
          ></b-button>
        </template>
      </b-table>
      <b-row>
        <b-col>
          <b-button class="mb-3" variant="outline-primary" v-on:click="showAddFarmerDialog">農家追加</b-button>
        </b-col>
      </b-row>
      <b-table
        id="authGroupOperator"
        responsive="sm"
        :fields="authGroupFields"
        :items="this.authGroupsOperators"
      >
        <template v-if="authGroupsOperators.length != 0" v-slot:cell(farmer)="data">
          <td>{{data.item.node.farmer.name}}</td>
        </template>
        <template v-if="authGroupsOperators.length != 0" v-slot:cell(authGroup)="data">
          <td>{{data.item.node.authGroup.name}}</td>
        </template>
        <template v-slot:cell(delete)="data">
          <b-button
            type="submit"
            size="sm"
            variant="danger"
            class="icon-trash"
            v-bind="authGroupOperator"
            v-on:click="showDeleteAuthGroupDialog(data.item.node.id)"
          ></b-button>
        </template>
      </b-table>
      <b-row>
        <b-col>
          <b-button class="mb-3" variant="outline-primary" v-on:click="showAddAuthGroupDialog">権限追加</b-button>
        </b-col>
      </b-row>

      <b-row>
        <b-col class="text-right">
          <b-button variant="primary" v-on:click="close">完了</b-button>
        </b-col>
      </b-row>
    </b-modal>
  </div>
</template>
<script>
import FarmersOperatorsCreateModalForm from "./FarmersOperatorsCreateModalForm";
import FarmersOperatorsDeleteModalForm from "./FarmersOperatorsDeleteModalForm";
import AuthGroupsOperatorsCreateModalForm from "./AuthGroupsOperatorsCreateModalForm";
import AuthGroupsOperatorsDeleteModalForm from "./AuthGroupsOperatorsDeleteModalForm";
import UserInfoUpdateForm from "./UserInfoUpdateForm";
import { CURRENT_OPERATOR_QUERY } from "../../components/operator-query";
import { DELETE_AUTH_GROUP_OPERATOR } from "../../components/auth-groups-operators-mutation";

export default {
  components: {
    FarmersOperatorsCreateModalForm,
    FarmersOperatorsDeleteModalForm,
    AuthGroupsOperatorsCreateModalForm,
    AuthGroupsOperatorsDeleteModalForm,
    UserInfoUpdateForm
  },
  props: {
    propOperator: {
      default: null
    }
  },
  watch: {
    propOperator: function() {
      this.init();
      this.operator = this.propOperator;
      this.operatorId = this.operator.id;
      this.getOperatorInfo();
    }
  },
  data() {
    return {
      operator: null,
      operatorId: null,
      authGroupFields: [
        { key: "farmer", label: "農家" },
        { key: "authGroup", label: "権限" },
        { key: "delete", label: "削除" }
      ],
      farmerFields: [
        { key: "farmer", label: "農家" },
        { key: "delete", label: "削除" }
      ],
      authGroupsOperators: [],
      authGroupOperator: null,
      authGroupsOperatorsId: null,
      farmersOperators: [],
      farmersOperator: null,
      farmersOperatorsId: null
    };
  },
  methods: {
    init: function() {
      this.operator = null;
      this.operatorId = null;
      this.authGroupsOperators = [];
    },
    // --------------------------------
    // modalを閉じる
    // --------------------------------
    close: function() {
      this.$emit("refetch");
      this.$refs["updateDialog"].hide();
    },
    // --------------------------------
    // modalを開く
    // --------------------------------
    showDialog: function() {
      this.$refs["updateDialog"].show();
    },
    showAddFarmerDialog: function() {
      this.$refs.farmersOperatorsCreateModalForm.showDialog();
    },
    showAddAuthGroupDialog: function() {
      this.$refs.authGroupsOperatorsCreateModalForm.showDialog();
    },
    showDeleteFarmerDialog: function(farmersOperatorsId) {
      this.farmersOperatorsId = farmersOperatorsId;
      this.$refs.farmersOperatorsDeleteModalForm.showDialog();
    },
    showDeleteAuthGroupDialog: function(authGroupsOperatorsId) {
      this.authGroupsOperatorsId = authGroupsOperatorsId;
      this.$refs.authGroupsOperatorsDeleteModalForm.showDialog();
    },
    getOperatorInfo: function() {
      this.$apollo.addSmartQuery("operator", {
        query: CURRENT_OPERATOR_QUERY,
        variables: {
          id: this.operatorId
        },
        result({ data }) {
          this.authGroupsOperators = data.operator.authgroupsoperatorsSet.edges;
          this.farmersOperators = data.operator.farmersoperatorsSet.edges;
        }
      });
    },
    setDeletedFarmerId: function(farmerId) {
      let deleteAuthGroupsOperatorsIds = [];

      this.authGroupsOperators.forEach(element => {
        if (element.node.farmer.id == farmerId) {
          this.deleteAuthGroupOperator(element.node.id);
        }
      });
      this.refetch();
    },
    deleteAuthGroupOperator: function(authGroupsOperatorsId) {
      this.$apollo
        .mutate({
          mutation: DELETE_AUTH_GROUP_OPERATOR,
          variables: {
            id: authGroupsOperatorsId
          }
        })
        .then(data => {
          this.refetch();
        })
        .catch(error => {
          console.error(error);
          alert(error);
        });
    },
    refetch: function() {
      this.$apollo.queries.operator.refetch();
    }
  }
};
</script>
