<template>
  <div>
    <b-form-group>
      <b-form-select
        v-model="authGroup"
        v-on:change="selectedAuthGroup"
        required
        :state="validation"
      >
        <option :value="null" disabled>-- 権限を選択してください --</option>
        <option
          v-for="item in authGroups.edges"
          :key="item.node.id"
          :value="item.node"
        >{{ item.node.name }}</option>
      </b-form-select>
      <b-form-invalid-feedback :state="validation">
        {{
        validMsg
        }}
      </b-form-invalid-feedback>
    </b-form-group>
  </div>
</template>

<script>
import { AUTH_GROUPS_QUERY } from "../../../components/auth-group-query";
export default {
  props: {
    propAuthGroup: {
      default: null
    },
    propValidMsg: {
      default: ""
    },
    propValidation: {
      default: null
    }
  },
  watch: {
    propAuthGroup: function() {
      this.authGroup = this.propAuthGroup;
    },
    propValidMsg: function() {
      this.validMsg = this.propValidMsg;
    },
    propValidation: function() {
      this.validation = this.propValidation;
    }
  },
  data() {
    return {
      authGroup: this.propAuthGroup,
      authGroups: [],
      validMsg: "",
      validation: null
    };
  },
  apollo: {
    authGroups: {
      query: AUTH_GROUPS_QUERY,
      result({ data, loading }) {},
      error(error) {
        console.error("authGroups", error);
      }
    }
  },
  methods: {
    selectedAuthGroup: function() {
      this.$emit("getAuthGroup", this.authGroup);
    }
  }
};
</script>
