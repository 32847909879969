<template>
  <div>
    <h3>農家選択</h3>
    <b-row>
      <b-col>
        <farmer-form
          :propValidMsg="validMsg"
          :propValidation="validStatus"
          @changeFarmer="setFarmer"
        ></farmer-form>
      </b-col>
      <b-col>
        <b-button type="submit" variant="outline-primary" v-on:click="createFarmerOperator">追加</b-button>
      </b-col>
    </b-row>
    <b-table id="currentFarmer" responsive="sm" :fields="fields" :items="this.farmersOperators">
      <template v-if="farmersOperators.length != 0" v-slot:cell(farmer)="data">
        <td>{{data.item.node.farmer.name}}</td>
      </template>
      <template v-slot:cell(delete)="data">
        <b-button
          type="submit"
          size="sm"
          variant="danger"
          class="icon-trash"
          v-bind="operator"
          v-on:click="deleteFarmerOperatorSet(data.item.node.id)"
        ></b-button>
      </template>
    </b-table>
    <b-row>
      <b-col class="text-right">
        <b-button type="submit" variant="primary" v-on:click="nextPage">登録</b-button>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import FarmerForm from "./form/FarmerForm";
import {
  CREATE_FARMER_OPERATOR,
  DELETE_FARMER_OPERATOR
} from "../../components/farmer-operator-mutation";
import { OPERATORS_FARMER_QUERY } from "../../components/operator-query";

export default {
  components: {
    FarmerForm
  },
  props: {
    propOperator: {
      default: null
    }
  },
  data() {
    return {
      operator: this.propOperator,
      farmersOperators: [],
      farmer: null,
      fields: [
        { key: "farmer", label: "農家" },
        { key: "delete", label: "削除" }
      ],
      validMsg: "",
      validStatus: null
    };
  },
  watch: {
    propOperator: function() {
      this.operator = this.propOperator;
      if (this.operator) {
        this.getCrrentFarmer();
      }
    }
  },
  methods: {
    setFarmer: function(farmer) {
      this.farmer = farmer;
    },
    deleteFarmerOperatorSet: function(farmersOperatorsId) {
      this.$apollo
        .mutate({
          mutation: DELETE_FARMER_OPERATOR,
          variables: {
            id: farmersOperatorsId
          }
        })
        .then(result => {
          this.refetch();
        });
    },
    getCrrentFarmer: function() {
      this.$apollo.addSmartQuery("operator", {
        query: OPERATORS_FARMER_QUERY,
        variables: {
          id: this.operator.id
        },
        result({ data }) {
          this.farmersOperators = data.operator.farmersoperatorsSet.edges;
        }
      });
    },
    checkDuplicate: function() {
      this.validMsg = "";
      this.validStatus = null;
      this.farmersOperators.forEach(element => {
        if (element.node.farmer.id == this.farmer.id) {
          this.validMsg = "既に登録されています";
          this.validStatus = false;
        }
      });
    },
    createFarmerOperator: function() {
      this.checkDuplicate();
      if (this.validStatus == null) {
        this.$apollo
          .mutate({
            mutation: CREATE_FARMER_OPERATOR,
            variables: {
              input: {
                farmerId: this.farmer.id,
                operatorId: this.operator.id
              }
            }
          })
          .then(result => {
            this.refetch();
          })
          .catch(error => {
            alert(error);
          });
      }
    },
    refetch: function() {
      this.$apollo.queries.operator.refetch();
    },
    nextPage: function() {
      this.$emit("getFarmer", this.farmersOperators);
    }
  }
};
</script>
