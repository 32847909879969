<template>
  <div>
    <b-form-group>
      <b-form-select v-model="farmer" v-on:change="regist" required :state="validation">
        <option :value="null" disabled>-- 農家を選択してください --</option>
        <option
          v-for="item in farmersOperators"
          :key="item.node.farmer.id"
          :value="item.node.farmer"
        >{{ item.node.farmer.name }}</option>
      </b-form-select>
      <b-form-invalid-feedback :state="validation">
        {{
        validMsg
        }}
      </b-form-invalid-feedback>
    </b-form-group>
  </div>
</template>
<script>
import { OPERATORS_FARMER_QUERY } from "../../../components/operator-query";
import { FARMER_ID } from "../../../components/auth-settings";
export default {
  props: {
    propOperator: {
      default: null
    },
    propFarmersOperators: {
      default: null
    },
    propValidMsg: {
      default: ""
    },
    propValidation: {
      default: null
    }
  },
  data() {
    return {
      operator: this.propOperator,
      farmer: null,
      farmersOperators: this.propFarmersOperators,
      validMsg: "",
      validation: null
    };
  },
  watch: {
    propOperator: function() {
      this.operator = this.propOperator;
      if (this.operator != null) {
        this.getOperatorsFarmer();
        this.refetch();
      }
    },
    propFarmersOperators: function() {
      this.farmersOperators = this.propFarmersOperators;
    },
    propValidMsg: function() {
      this.validMsg = this.propValidMsg;
    },
    propValidation: function() {
      this.validation = this.propValidation;
    }
  },
  methods: {
    getOperatorsFarmer: function() {
      this.$apollo.addSmartQuery("operator", {
        query: OPERATORS_FARMER_QUERY,
        variables: {
          id: this.operator.id
        },
        result({ data }) {
          this.farmersOperators = data.operator.farmersoperatorsSet.edges;
        }
      });
    },
    refetch: function() {
      this.$apollo.queries.operator.refetch().then(result => {
        this.farmersOperators = result.data.operator.farmersoperatorsSet.edges;
      });
    },
    regist: function() {
      this.$emit("changeFarmer", this.farmer);
    }
  }
};
</script>
