import gql from "graphql-tag";

export const CREATE_FARMER_OPERATOR = gql`
  mutation createFarmerOperator($input: FarmerOperatorInput!) {
    createFarmerOperator(input: $input) {
      farmerOperator {
        id
        operator {
          id
          username
        }
        farmer {
          id
        }
      }
    }
  }
`;

export const DELETE_FARMER_OPERATOR = gql`
  mutation deleteFarmerOperator($id: ID!) {
    deleteFarmerOperator(id: $id) {
      farmerOperator {
        id
        operator {
          id
          username
        }
        farmer {
          id
        }
      }
    }
  }
`;
