<template>
  <div>
    <b-modal ref="dialog" ok-only>{{this.message}}</b-modal>
    <h3>新規登録</h3>
    <b-form>
      <b-form-group id="username-input" label="Your Name:" label-for="lastName">
        <b-row>
          <b-col>
            <b-form-input
              id="lastName"
              v-model="form.lastName"
              placeholder="姓"
              :state="validStatus.lastName"
            ></b-form-input>
            <b-form-invalid-feedback :state="validStatus.lastName">
              {{
              validMsg.lastName
              }}
            </b-form-invalid-feedback>
          </b-col>
          <b-col>
            <b-form-input
              id="firstName"
              v-model="form.firstName"
              placeholder="名"
              :state="validStatus.firstName"
            ></b-form-input>
            <b-form-invalid-feedback :state="validStatus.firstName">
              {{
              validMsg.firstName
              }}
            </b-form-invalid-feedback>
          </b-col>
        </b-row>
      </b-form-group>
      <b-form-group id="email-input" label="Email address:" label-for="E-mail" description="半角英数字">
        <b-form-input
          id="email"
          v-model="form.email"
          placeholder="Enter email"
          :state="validStatus.email"
        ></b-form-input>
        <b-form-invalid-feedback :state="validStatus.email">
          {{
          validMsg.email
          }}
        </b-form-invalid-feedback>
      </b-form-group>
      <b-form-group
        id="password-input"
        label="Password:"
        label-for="password"
        description="半角英数字8文字以上ハイフン・アンダーバーが使えます"
      >
        <b-form-input
          id="password"
          type="password"
          v-model="form.password"
          placeholder="Enter password"
          :state="validStatus.password"
        ></b-form-input>
        <b-form-invalid-feedback :state="validStatus.password">
          {{
          validMsg.password
          }}
        </b-form-invalid-feedback>
      </b-form-group>
      <b-button type="button" variant="primary" v-on:click="onSubmit">登録</b-button>
    </b-form>
  </div>
</template>

<script>
import { CREATE_OPERATOR } from "../../components/operator-mutation";

const DUPL_EMAIL_ERROR = "Duplicate entry";
const CREDENTIAL_ERROR = "Please, enter valid credentials";
const VALID_FIELDS = ["email", "password"]

export default {
  data() {
    return {
      form: {
        email: "",
        username: "",
        password: "",
        firstName: "",
        lastName: ""
      },
      operator: null,
      message: "",
      validMsg: {
        firstName: "",
        lastName: "",
        password: "",
        email: ""
      },
      validStatus: {
        firstName: null,
        lastName: null,
        password: null,
        email: null
      },
    };
  },
  methods: {
    init: function() {
      this.form = {
        email: "",
        username: "",
        password: "",
        firstName: "",
        lastName: ""
      };
      this.operator = null;
    },
    emptyFieldValid: function() {
      // 初期化
      let validResult = true;
      this.validMsg = {
        firstName: "",
        lastName: "",
        password: "",
        email: ""
      };
      this.validStatus = {
        firstName: null,
        lastName: null,
        password: null,
        email: null
      };

      this.form.username = this.form.lastName + " " + this.form.firstName;
      for (let field in this.form) {
        // フォームが空の場合メッセージを表示
        if (this.form[field] == "") {
          this.validStatus[field] = false;
          this.validMsg[field] = "必須項目です";

          validResult = false;
        }
      }

      return validResult
    },
    passwordValid: function() {
      let validResult = true;
      const pattern = /^([a-zA-Z0-9_-]+){8,}$/;

      if (!pattern.test(this.form.password)) {
        this.validStatus.password = false;
        this.validMsg.password = "パスワードの形式が間違っています";

        validResult = false;
      }

      return validResult
    },
    onSubmit: function() {
      // バリデーション
      if (this.emptyFieldValid() && this.passwordValid()) {
        this.createOperator();
      }
    },
    createOperator: function() {
      this.$apollo
        .mutate({
          mutation: CREATE_OPERATOR,
          variables: {
            username: this.form.username,
            firstName: this.form.firstName,
            lastName: this.form.lastName,
            password: this.form.password,
            email: this.form.email
          }
        })
        .then(result => {
          const valid_errors = result.data.createOperator.errors;
          if (valid_errors.length != 0) {
            valid_errors.forEach(valid_error => {
              for (let idx in VALID_FIELDS) {
                if(valid_error.field.match(VALID_FIELDS[idx])){
                  this.validMsg[VALID_FIELDS[idx]] = valid_error.messages[0];
                  this.validStatus[VALID_FIELDS[idx]] = false;
                }
              }
            });
          } else {
            this.operator = result.data.createOperator.user;
            this.message = "登録しました";
            this.$refs["dialog"].show();
            this.init();
            this.$emit("createdUser", result.data.createOperator.user);
          }
        })
        .catch(error => {
          if (error.message.match(DUPL_EMAIL_ERROR)) {
            this.validMsg.email = DUPL_EMAIL_ERROR;
            this.validStatus.email = false;
          }
          if (error.message.match(CREDENTIAL_ERROR)) {
            this.validMsg.email = CREDENTIAL_ERROR;
            this.validStatus.email = false;
            this.validMsg.password = CREDENTIAL_ERROR;
            this.validStatus.password = false;
          }
        });
    }
  }
};
</script>
