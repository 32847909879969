<template>
  <div>
    <b-modal ref="updateDialog" @hidden="close" hide-footer title="編集">
      <b-row>
        <b-col>
          <label>農家</label>
          <farmer-form
            :propFarmer="farmer"
            :propValidMsg="validMsg"
            :propValidation="validStatus"
            @changeFarmer="setFarmer"
          ></farmer-form>
        </b-col>
      </b-row>
      <b-row>
        <b-col class="text-right">
          <b-button variant="outline-primary" v-on:click="onClickFunc">追加</b-button>
        </b-col>
      </b-row>
    </b-modal>
  </div>
</template>
<script>
import FarmerForm from "./form/FarmerForm";
import { CREATE_FARMER_OPERATOR } from "../../components/farmer-operator-mutation";

export default {
  components: {
    FarmerForm
  },
  props: {
    propOperator: {
      default: null
    }
  },
  watch: {
    propOperator: function() {
      this.operator = this.propOperator;
    }
  },
  data() {
    return {
      operator: null,
      farmer: null,
      validMsg: "",
      validStatus: null
    };
  },
  methods: {
    init: function() {
      this.farmer = null;
      this.validMsg = "";
      this.validStatus = null;
    },
    // --------------------------------
    // modalを閉じる
    // --------------------------------
    close: function() {
      this.init();
      this.$refs["updateDialog"].hide();
    },
    // --------------------------------
    // modalを開く
    // --------------------------------
    showDialog: function() {
      this.$refs["updateDialog"].show();
    },
    setFarmer: function(farmer) {
      this.farmer = farmer;
    },
    checkDeplication: function() {
      for (let element of this.operator.farmersoperatorsSet.edges) {
        if (element.node.farmer.id == this.farmer.id) {
          this.validMsg = "すでに登録されています";
          this.validStatus = false;
          return this.validStatus;
        }
      }
    },
    onClickFunc: function() {
      const checkResult = this.checkDeplication();
      if (checkResult == null) {
        this.addUserFarmer();
        this.$emit("refetch");
        this.close();
      }
    },
    addUserFarmer: function() {
      this.$apollo.mutate({
        mutation: CREATE_FARMER_OPERATOR,
        variables: {
          input: {
            farmerId: this.farmer.id,
            operatorId: this.operator.id
          }
        }
      });
    }
  }
};
</script>
