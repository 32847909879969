<template>
  <div>
    <user-update-modal-form :propOperator="operator" ref="userUpdateModalForm" @refetch="refetch" />
    <user-delete-modal-form
      :propUserId="operatorId"
      ref="farmersOperatorsDeleteModalForm"
      @refetch="refetch"
    />
    <b-table id="operator" responsive="sm" :fields="fields" :items="operators.edges">
      <template v-slot:cell(username)="data">
        <td>{{data.item.node.username}}</td>
      </template>
      <template v-slot:cell(email)="data">
        <td>{{data.item.node.email}}</td>
      </template>
      <template v-slot:cell(update)="data">
        <b-button
          type="submit"
          size="sm"
          variant="primary"
          class="icon-pencil"
          v-bind="operator"
          v-on:click="showUpdateDialog(data.item.node)"
        ></b-button>
      </template>
      <template v-slot:cell(delete)="data">
        <b-button
          type="submit"
          size="sm"
          variant="danger"
          class="icon-trash"
          v-bind="operator"
          v-on:click="showDeleteDialog(data.item.node.id)"
        ></b-button>
      </template>
    </b-table>
  </div>
</template>
<script>
import UserUpdateModalForm from "./UserUpdateModalForm";
import UserDeleteModalForm from "./UserDeleteModalForm";
import { OPERATOR_QUERY } from "../../components/operator-query";

export default {
  components: {
    UserUpdateModalForm,
    UserDeleteModalForm
  },
  data() {
    return {
      operators: [],
      operator: null,
      operatorId: null,
      fields: [
        { key: "username", label: "氏名" },
        { key: "email", label: "メールアドレス" },
        { key: "update", label: "編集" },
        { key: "delete", label: "削除" }
      ]
    };
  },
  methods: {
    init: function() {
      this.operators = [];
    },
    // --------------------------------
    // 編集 modalを表示
    // --------------------------------
    showUpdateDialog: function(operator) {
      this.operator = operator;
      this.$refs.userUpdateModalForm.showDialog();
    },
    // --------------------------------
    // 削除 modalを表示
    // --------------------------------
    showDeleteDialog: function(operatorId) {
      this.operatorId = operatorId;
      this.$refs.farmersOperatorsDeleteModalForm.showDialog();
    },
    // --------------------------------
    // 再取得
    // --------------------------------
    refetch: function() {
      this.$apollo.queries.operators.refetch();
    }
  },
  apollo: {
    operators: {
      query: OPERATOR_QUERY,
      result({ data }) {},
      error(error) {
        console.error(error);
      }
    }
  }
};
</script>
