import gql from "graphql-tag";

export const CREATE_AUTH_GROUP_OPERATOR = gql`
  mutation($input: AuthGroupOperatorInput!) {
    createAuthGroupOperator(input: $input) {
      authGroupOperator {
        id
        operator {
          id
          username
        }
        authGroup {
          id
          name
        }
        farmer {
          id
          name
        }
      }
    }
  }
`;

export const UPDATE_AUTH_GROUP_OPERATOR = gql`
  mutation($id: ID!, $input: AuthGroupOperatorInput!) {
    updateAuthGroupOperator(id: $id, input: $input) {
      authGroupOperator {
        id
        operator {
          id
          username
        }
        authGroup {
          id
          name
        }
        farmer {
          id
          name
        }
      }
    }
  }
`;

export const DELETE_AUTH_GROUP_OPERATOR = gql`
  mutation($id: ID!) {
    deleteAuthGroupOperator(id: $id) {
      authGroupOperator {
        id
        operator {
          id
          username
        }
        authGroup {
          id
          name
        }
        farmer {
          id
          name
        }
      }
    }
  }
`;
