import gql from "graphql-tag";

export const AUTH_GROUPS_QUERY = gql`
  query {
    authGroups {
      edges {
        node {
          id
          name
        }
      }
    }
  }
`;
