<template>
  <div>
    <h3>権限選択</h3>
    <b-row>
      <b-col>
        <operators-farmer-form
          ref="operatorsFarmerForm"
          :propOperator="operator"
          :propFarmersOperators="this.propFarmersOperators"
          @changeFarmer="setFarmer"
        ></operators-farmer-form>
      </b-col>
      <b-col>
        <auth-group-form
          :propValidMsg="validMsg"
          :propValidation="validStatus"
          @getAuthGroup="setAuthGroup"
        ></auth-group-form>
      </b-col>
      <b-col>
        <b-button type="submit" variant="outline-primary" v-on:click="createAuthGroupOperator">追加</b-button>
      </b-col>
    </b-row>
    <b-table
      id="authGroupFarmer"
      responsive="sm"
      :fields="fields"
      :items="this.authGroupsOperators"
    >
      <template v-if="authGroupsOperators.length != 0" v-slot:cell(farmer)="data">
        <td>{{data.item.node.farmer.name}}</td>
      </template>
      <template v-if="authGroupsOperators.length != 0" v-slot:cell(authGroup)="data">
        <td>{{data.item.node.authGroup.name}}</td>
      </template>
      <template v-slot:cell(delete)="data">
        <b-button
          type="submit"
          size="sm"
          variant="danger"
          class="icon-trash"
          v-bind="operator"
          v-on:click="deleteAuthGroupOperator(data.item.node.id)"
        ></b-button>
      </template>
    </b-table>
    <b-row>
      <b-col class="text-right">
        <b-button type="submit" variant="primary" v-on:click="setInfo">登録</b-button>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import AuthGroupForm from "./form/AuthGroupForm";
import OperatorsFarmerForm from "./form/OperatorsFarmerForm";
import {
  CREATE_AUTH_GROUP_OPERATOR,
  DELETE_AUTH_GROUP_OPERATOR
} from "../../components/auth-groups-operators-mutation";
import { OPERATORS_AUTH_GROUP_QUERY } from "../../components/operator-query";

export default {
  components: {
    AuthGroupForm,
    OperatorsFarmerForm
  },
  props: {
    propOperator: {
      default: null
    },
    propFarmersOperators: {
      default: null
    }
  },
  data() {
    return {
      operator: null,
      authGroupsOperators: [],
      farmer: null,
      authGroup: null,
      fields: [
        { key: "farmer", label: "農家" },
        { key: "authGroup", label: "権限" },
        { key: "delete", label: "削除" }
      ],
      validMsg: "",
      validStatus: null
    };
  },
  watch: {
    propOperator: function() {
      this.operator = this.propOperator;
      if (this.operator != null) {
        this.getCrrentAuthGroup();
      }
    }
  },
  methods: {
    setFarmer: function(farmer) {
      this.farmer = farmer;
    },
    setAuthGroup: function(authGroup) {
      this.authGroup = authGroup;
    },
    deleteAuthGroupOperator: function(authGroupsOperatorsId) {
      this.$apollo
        .mutate({
          mutation: DELETE_AUTH_GROUP_OPERATOR,
          variables: {
            id: authGroupsOperatorsId
          }
        })
        .then(result => {
          this.refetch();
        });
    },
    getCrrentAuthGroup: function() {
      this.$apollo.addSmartQuery("operator", {
        query: OPERATORS_AUTH_GROUP_QUERY,
        variables: {
          id: this.operator.id
        },
        result({ data }) {
          this.authGroupsOperators = data.operator.authgroupsoperatorsSet.edges;
        }
      });
    },
    setInfo: function() {
      this.$emit("getInfo");
    },
    checkDuplicate: function() {
      this.validMsg = "";
      this.validStatus = null;
      this.authGroupsOperators.forEach(element => {
        if (element.node.farmer.id == this.farmer.id) {
          if (element.node.authGroup.id == this.authGroup.id) {
            this.validMsg = "既に登録されています";
            this.validStatus = false;
          }
        }
      });
    },
    createAuthGroupOperator: function() {
      this.checkDuplicate();
      if (this.validStatus == null) {
        this.$apollo
          .mutate({
            mutation: CREATE_AUTH_GROUP_OPERATOR,
            variables: {
              input: {
                farmerId: this.farmer.id,
                operatorId: this.operator.id,
                authGroupId: this.authGroup.id
              }
            }
          })
          .then(result => {
            this.refetch();
          })
          .catch(error => {
            alert(error);
          });
      }
    },
    refetch: function() {
      this.$apollo.queries.operator.refetch();
    }
  }
};
</script>
